'use client';

import React, { FC } from 'react';
import { useRouter } from 'next/navigation';
import { Territory } from 't2-graphql-types';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import DropdownItem from '../Dropdown/DropdownItem';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import getTerritoryPageUrl from '../../services/pagesUrl/getTerritoryPageUrl';
import Label from '../Label/Label';
import Typo from '../Typography/Typo';
import { isWithinDays } from './helpers/isWithinDays';

type TNavbarTerritoriesDropdownItemsProps = {
  territories: Array<Pick<Territory, 'id' | 'slug' | 'name' | 'createdAt'>>;
};

const NavbarTerritoriesDropdownItems: FC<TNavbarTerritoriesDropdownItemsProps> = ({ territories }) => {
  const router = useRouter();
  const { sendEvent } = useAnalytics();

  const handleClickTerritory = (territory: Pick<Territory, 'id' | 'slug'>) => {
    sendEvent('navbar_territory_dropdown_item_click', { territory_id: territory.id });
    router.push(getTerritoryPageUrl(territory.slug!));
  };

  return (
    <>
      {territories.map((territory) => {
        const dateToCheck: string = territory?.createdAt;
        const numDays: number = 30;
        const isWithinThirtyDays: boolean = isWithinDays(dateToCheck, numDays);

        return (
          <DropdownItem key={territory.id} className="max-h-[48px]">
            <DropdownClickableItem className="items-center" onClick={() => handleClickTerritory(territory)}>
              <div className="w-full text-left">t/{territory.name}</div>
              <div className="ml-3 w-[60px]">
                {isWithinThirtyDays && (
                  <Label backgroundColor="secondary" size="small" textColor="primary">
                    <Typo variant="small-p" className="!text-xs">
                      New!
                    </Typo>
                  </Label>
                )}
              </div>
            </DropdownClickableItem>
          </DropdownItem>
        );
      })}
    </>
  );
};
export default NavbarTerritoriesDropdownItems;
