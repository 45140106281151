const path = require('path');

const nodeModulesPath = path.resolve(__dirname, '../../', 'node_modules');
const flowbitePath = `${nodeModulesPath}/flowbite-react/lib/esm/**/*.js`;

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}', flowbitePath],
  theme: {
    boxShadow: {
      tab: '0px 4px 20px rgba(141, 141, 141, 0.25)',
      'tab-active': '0px 4px 20px rgba(235, 67, 1, 0.4)',
      'editor-select': '0px 10px 15px -3px rgba(0, 0, 0, 0.05)',
    },
    extend: {
      containers: {
        xxxs: '200px',
      },
      screens: {
        xs: '450px',
      },
      backgroundImage: {
        partyPropper: "url('/images/party-popper.svg')",
        'double-quotation': "url('/images/double-quotation.svg')",
        'notification-success': "url('/images/notifications/notification-success.svg')",
        'notification-danger': "url('/images/notifications/notification-danger.svg')",
        hourglass: "url('/images/hourglass.svg')",
        'tab-noisy': "url('/images/noisy.jpeg')",
        'metamask-logo': "url('/images/metamask-logo.svg')",
        arrowRight: "url('/images/arrow-right.svg')",
        arrowRightUpDark: "url('/images/arrow-right-up-dark.svg')",
        'user-placeholder-color': 'linear-gradient(179.96deg, #1A1A1A 0.04%, rgba(256, 256, 256, 1) 170.77%)',
        'copy-icon': 'url(/images/copy.svg)',
        'link-icon': 'url(/images/link.svg)',
        'input-gradient-right': 'linear-gradient(to left, #FFFFFF, #FFFFFF 10%,rgba(24,24,24,0) 90%);',
        twitter: "url('/images/twitter.svg')",
        'twitter-black': "url('/images/twitter-black.svg')",
        'lenster-black': "url('/images/lenster-black.svg')",
        lenster: "url('/images/lenster.svg')",
        'post-img-placeholder': "url('/images/post-image-placeholder.webp')",

        'img-icon': "url('/images/img-icon.svg')",
        'gift-icon': "url('/images/gift-icon.svg')",
        'bin-icon': "url('/images/bin.svg')",
        calendar: "url('/images/calendar.svg')",
        'curved-arrow-right': "url('/images/curved-arrow-right.svg')",
        'home-icon': "url('/images/home.svg')",
        'discord-outline': "url('/images/discord-outline.svg')",
        'bonsai-logo': "url('/images/bonsai-logo.webp')",
        'bonsai-copy-desktop': "url('/images/bonsai-copy-desktop.svg')",
        'bonsai-copy-tablet': "url('/images/bonsai-copy-tablet.svg')",
        'bonsai-copy-mobile': "url('/images/bonsai-copy-mobile.svg')",
        'bonsai-desktop': "url('/images/bonsai-desktop.webp')",
        'bonsai-tablet': "url('/images/bonsai-tablet.webp')",
        'bonsai-mobile': "url('/images/bonsai-mobile.webp')",
        'bonsai-multiple-logos': "url('/images/bonsai-multiple-logos.webp')",
        't2-summer-grant-mobile': "url('/images/summer-grant-mobile.webp')",
        't2-summer-grant-logo': "url('/images/t2-summer-grant-logo.svg')",
        't2-summer-grant-copy-desktop': "url('/images/t2-summer-grant-copy-desktop.svg')",
        't2-summer-grant-copy-tablet': "url('/images/t2-summer-grant-copy-tablet.svg')",
      },
      keyframes: {
        'discord-up': {
          '0%': {
            opacity: '0',
            transform: 'translateY(100%)',
          },
          '100%': {
            opacity: '1',
            transform: 'translateY(0%)',
          },
        },
        'discord-down': {
          '0%': {
            opacity: '1',
            transform: 'translateY(0%)',
          },
          '100%': {
            opacity: '0',
            transform: 'translateY(100%)',
          },
        },
        'fade-in': {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
        'fade-out': {
          from: {
            opacity: '1',
          },
          to: {
            opacity: '0',
          },
        },
        wiggle: {
          '0%, 100%': {
            transform: 'rotate(-60deg)',
          },
          '50%': { transform: 'rotate(60deg)' },
        },
        'heart-beat': {
          '0%, 100%': {
            transform: 'scale(1)',
          },
          '50%': { transform: 'scale(2)' },
        },
        'moving-dots': {
          from: {
            transform: 'translateX(0px)',
            left: '0px',
          },
          to: {
            transform: 'translateX(10px)',
            left: '10px',
          },
        },
      },
      animation: {
        'spin-slow': 'spin 3s linear infinite',
        wiggle: 'wiggle 1s ease-in-out infinite',
        'heart-beat': 'heart-beat 1s ease-in-out infinite',
        'moving-dots': 'moving-dots 2s 1s ease-out infinite',
        'fade-in': 'fade-in 0.4s ease-out',
        'fade-out': 'fade-out 0.4s ease-out',
        'discord-up': 'discord-up 0.2s ease-out',
        'discord-down': 'discord-down 0.2s ease-out',
        'tooltip-in': 'fade-in 0.2s ease-in',
        'tooltip-out': 'fade-out 0.2s ease-in',
      },
      height: {
        'screen/2': '50vh',
        'screen/3': 'calc(100vh / 3)',
        'screen/4': 'calc(100vh / 4)',
        'screen/5': 'calc(100vh / 5)',
        'screen-custom': 'calc(100vh - 80px)',
        540: '33.7rem',
      },
      aspectRatio: {
        '4/1': '4 / 1',
        '4/2': '4 / 2',
      },
      flex: {
        2: '2 2 0%',
      },
      gridColumnStart: {
        n1: '-1',
      },
      minHeight: {
        fitScreen: 'calc(100vh - 72px + 111px)',
      },
      minWidth: {
        91: '91px',
      },
      maxWidth: {
        11: '11.313rem',
        13: '13.313rem',
        15: '15.75rem',
        21: '21.813rem',
        23: '22.813rem',
        40: '40rem',
        44: '44.625rem',
        50: '50rem',
        56: '56.938rem',
      },
      colors: {
        t2: {
          neutral: {
            grey01: '#F9F9F9',
            grey02: '#F4F4F4',
            grey03: '#E9E9E9',
            grey04: '#BDBDBD',
            grey05: '#929292',
            grey06: '#5A5A5A',
            grey07: '#363636',
            grey08: '#242424',
            grey09: '#131313',
          },
          primary: {
            beige: '#EFEAE6',
            brandColor: '#F85E4A',
          },
          success: {
            lightGreen: '#ECFDF5',
            neonGreen: '#0BD89F',
            darkGreen: '#047857',
          },
          warning: {
            lightYellow: '#FFFBEB',
            darkYellow: '#D97706',
          },
          error: {
            lightRed: '#FEF2F2',
            darkRed: '#DC2626',
          },
          shades: {
            white: '#FFFFFF',
            black: '#000000',
          },
        },
        gray: {
          100: '#F4F4F4',
          200: '#E9E9E9',
          300: '#C1C1C1',
          400: '#929292',
          600: '#808080',
          700: '#242424',
        },
        black: {
          700: '#131313',
        },
        green: {
          100: '#ECFDF5',
          200: '#ECF9F3',
          300: '#D6E8E0',
          600: '#047857',
        },
        yellow: {
          50: '#FFFBEB',
          100: '#FEFFC2',
          700: '#FFCD29',
        },
        red: {
          100: '#FEF2F2',
          800: '#DC2626',
        },
        white: {
          100: '#F9F9F9',
          900: '#FFFFFF',
        },
        orange: {
          500: '#F85E4A',
          900: '#DA3B27',
        },
        beige: {
          100: '#EFEAE6',
        },
        lens: {
          primary: '#3D4B41',
        },
        twitter: {
          primary: '#1DA1F2',
        },
        neutral: {
          200: '#F4F4F4',
          700: '#363636',
        },
      },
      borderRadius: {
        sm: '5px',
        lg: '10px',
        xl: '15px',
        full: '100%',
        '2xl': '20px',
        '3xl': '22px',
        '3.5xl': '24px',
        '4xl': '30px',
      },
      content: {
        bookLearnMore: 'url(/images/book.svg)',
        arrowRightUpOrange: 'url(/images/arrow-right-up-orange.svg)',
        arrowRightUp: 'url(/images/arrow-right-up.svg)',
        memberOrange: 'url(/images/member-orange.svg)',
        arrowRightUpDark: 'url(/images/arrow-right-up-dark.svg)',
        plusBlack: 'url(/images/plus-black.svg)',
        exitBlack: 'url(/images/exit-black.svg)',
        discord: 'url(/images/discord.svg)',
        inspireDefault: 'url(/images/inspire-default.svg)',
        inspireActive: 'url(/images/inspire-active.svg)',
        bin: 'url(/images/bin.svg)',
        restricted: 'url(/images/restricted.svg)',
        greenBook: 'url(/images/book-green.svg)',
        flag: 'url(/images/flag.svg)',
        editPencilBlack: 'url(/images/edit-pencil-black.svg)',
        arweaveLogo: 'url(/images/arweave-logo.svg)',
        plus: 'url(/images/plus.svg)',
        plusGray: 'url(/images/plus-gray.svg)',
        editPencil: 'url(/images/edit-pencil.svg)',
      },
      fontFamily: {
        primary: ['Ivar Display'],
        secondary: ['Suisse Intl'],
      },
      fontSize: {
        'p2-m': ['0.875rem', '1.25rem'], // 14px 20px
        p4: ['1rem', '1.5rem'], // 16px 24px
        p3: ['1.25rem', '1.875rem'], // 20px 30px
        p2: ['1.125rem', '1.75rem'], // 18px 28px
        'label-m': ['1.125rem', '1rem'], // 18px 16px
        label: ['0.875rem', '1rem'], // 16px 16px
        p1: ['1.5rem', '2rem'], // 24px 32px
        'h4-m': ['1rem', '1.25rem'], // 16px 20px
        'h3-m': ['1.25rem', '1.5rem'], // 20px 24px
        'h2-m': ['1.5rem', '1.75rem'], // 24px 28px
        'h1-m': ['2rem', '2.25rem'], // 32px 36px
        h4: ['1.5rem', '2.125rem'], // 24p 34px
        h3: ['2rem', '2.25rem'], // 32x 36px
        h2: ['2.5rem', '2.75rem'], // 40x 44px
        h1: ['3.5rem', '4rem'], // 56px 64px
        article: ['1.25rem', '2rem'], // 20px 32px
      },
      boxShadow: {
        contribution: '0px 4px 30px rgba(0, 0, 0, 0.25)',
        slider: '12px 10px 50px rgba(0, 0, 0, 0.4)',
        readingHistory: '0px 7px 30px rgba(132, 132, 132, 0.25)',
        discordSupport: '0px 4px 10px rgba(141, 141, 141, 0.25)',
        exploreTerritory: '0px 6px 12px rgba(249, 249, 249, 0.11)',
        staticShadow: '0px 4px 8px rgba(26, 26, 26, 0.08)',
        hoverShadow: '0px 6px 12px rgba(26, 26, 26, 0.11)',
        none: '0 0 #0000',
      },
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require('@tailwindcss/container-queries'),
    // eslint-disable-next-line global-require
    require('flowbite/plugin'),
    // ...
  ],
};
