import Link from 'next/link';
import { useBannerContext } from '../Banner/BannerContext';
import LensLogo from '../UserSettings/icons/LensLogo';
import { getSettingsPageUrl } from '../../services/pagesUrl/getSettingsPageUrl';
import { SettingsSubpage } from '../UserSettings/constants';

type TLensIntegrationBannerContentProps = {
  onRedirectLinkClick: () => void;
};

export const LensIntegrationBannerContent = ({ onRedirectLinkClick }: TLensIntegrationBannerContentProps) => {
  const { closeBanner } = useBannerContext();

  const onLinkClick = () => {
    onRedirectLinkClick();
    closeBanner();
  };

  return (
    <div className="flex justify-center">
      <div className="flex justify-center items-center pr-2">
        <LensLogo className="fill-lens-primary" />
      </div>
      <span>
        Looks like you've got a Lens profile! Connect it anytime in your{' '}
        <Link
          onClick={onLinkClick}
          href={getSettingsPageUrl(SettingsSubpage.lens)}
          className="underline cursor-pointer hover:text-orange-500"
        >
          profile settings
        </Link>
        .
      </span>
    </div>
  );
};
