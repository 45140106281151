import Typo from '../Typography';

type TNotificationTitleProps = {
  initiatorName: string;
  content: string;
};

export const NotificationCardTitleWithInitiator = ({ initiatorName, content }: TNotificationTitleProps) => (
  <Typo className="text-[12px] md:text-[14px]">
    <span className="font-bold">{initiatorName} </span>
    {content}
  </Typo>
);
